var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-card-group",
        { staticClass: "mb-4" },
        _vm._l(_vm.arrayCounters, function (counter, index) {
          return _c("fine-status-widget", {
            key: index,
            attrs: {
              to: counter[1].to,
              active: _vm.statusWidgetIsActive(counter[1].active),
              description: counter[1].description,
              title: counter[1].value,
              value: counter[1].value,
              max: counter[1].max,
              icon: counter[1].icon,
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }