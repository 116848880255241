var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "card-pointer",
      on: {
        click: function ($event) {
          return _vm.goTo(_vm.to)
        },
      },
    },
    [
      _c("div", { staticClass: "h3 text-muted text-right mb-0" }, [
        _c("i", {
          class: { [this.icon]: this.icon, "text-success": this.active },
        }),
      ]),
      _c("div", { staticClass: "h4 mb-0" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _c(
        "small",
        { staticClass: "text-muted text-uppercase font-weight-bold" },
        [_vm._v(_vm._s(_vm.description))]
      ),
      _c("b-progress", {
        staticClass: "progress-xs mt-2 mb-0",
        attrs: { height: "{}", variant: this.variant, value: this.progress },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }