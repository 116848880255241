<script>
import FineStatusWidget from '@components/fines/status-widget'
import find from 'lodash/find'

export default {
  name: 'FineStatus',
  components: {
    FineStatusWidget,
  },
  props: {
    counters: {
      type: Object,
    }
  },
  data() {
    return {
      status: {},
    }
  },
  computed: {
    arrayCounters () {
      return Object.entries(this.counters);
    }
  },
  methods: {
    statusWidgetIsActive(routerName) {
      return this.$route.name === routerName
    },
  },
}
</script>

<template>
  <div>
    <b-card-group class="mb-4">
      <fine-status-widget
        v-for="(counter, index) in arrayCounters"
        :key="index"
        :to="counter[1].to"
        :active="statusWidgetIsActive(counter[1].active)"
        :description="counter[1].description"
        :title="counter[1].value"
        :value="counter[1].value"
        :max="counter[1].max"
        :icon="counter[1].icon"
      />
    </b-card-group>
  </div>
</template>
