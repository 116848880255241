<script>
import Layout from '@layouts/main';
import ContentControll from '@components/content-controll';
import ContentLoading from '@components/content-loading';
import FineStatus from '@components/fines/status';
import FineImages from '@components/fines/images';

import { formatPrice } from '@utils/numbers';

import FINE_LIST from '@graphql/fine/queries/list.gql';
import FINES_COUNT from '@graphql/fine/queries/listCount.gql';

import ModalFineImages from '@components/modals/fines/fine-images';

export default {
  name: 'Fine',
  page: {
    title: 'Multas',
  },
  components: {
    ModalFineImages,
    FineImages,
    Layout,
    ContentControll,
    ContentLoading,
    FineStatus,
  },
  mounted() {
    this.getFines();
    this.getFinesCounts();
  },
  data: () => {
    return {
      loading: false,
      fineOpened: {},
      modalShowFineImages: false,
      fineModal: {},
      fines: {
        count: 0,
        items: [],
      },
      finesCount: {
        processing: {
          to: "/fines",
          active: "fines",
          description: "Processando",
          value: 0,
          max: 40,
          icon: "fa fa-refresh"
        },
        pending_indication: {
          to: "/fines/pending",
          active: "fines-pending",
          description: "Indicação pendente",
          value: 0,
          max: 40,
          icon: "fa fa-hourglass-half"
        },
        indicated: {
          to: "/fines/indicated",
          active: "fines-indicated",
          description: "Indicado",
          value: 0,
          max: 40,
          icon: "fa fa-user"
        },
        charged: {
          to: "/fines/charged",
          active: "fines-charged",
          description: "Cobrado",
          value: 0,
          max: 40,
          icon: "fa fa-dollar"
        },
        paid: {
          to: "/fines/paid",
          active: "fines-paid",
          description: "Pago",
          value: 0,
          max: 40,
          icon: "fa fa-calendar"
        }
      },
      pagination: {
        current: 1,
        total: 10,
      },
      fields: [
        {
          key: 'car',
          label: 'Veículo',
          class: 'pw-140',
        },
        {
          key: 'driver',
          label: 'Motorista',
          class: 'pw-180',
        },
        {
          key: 'description',
          label: 'Infração',
        },
        {
          key: 'images',
          label: 'Imagens',
        },
        {
          key: 'issued_at',
          label: 'Data infração',
          class: 'pw-180',
        },
        {
          key: 'amount',
          label: 'Valor',
          class: 'pw-100 text-right',
        },
      ],
    };
  },
  methods: {
    openFineImagesModal( thisItem ) {
      this.modalShowFineImages = true;
      this.fineOpened = thisItem;
    },

    getAmountColor(type) {
      return type === 'CREDIT' ? '#4dbd74' : '#f86c6b';
    },
    setFormatPrice(value) {
      return formatPrice('R$', value);
    },
    checkIfNew(_issued) {
      const time_now = this.$moment();
      return time_now.diff(this.$moment(_issued), 'days') <= 14;
    },
    getFines () {
      this.$apollo.query({
        query: FINE_LIST,
        variables: {
          status: this.$route.meta.status,
          page: this.pagination.current - 1,
          limit: this.pagination.total,
        }
      })
      .then((data) => {
        const newData = data.data.fines;

        this.fines.count = newData.count;
        this.fines.items = newData.items;

        this.loading = true;
      });
    },
    getFinesCounts () {
      this.$apollo.query({
        query: FINES_COUNT
      })
      .then((data) => {
        const newData = data.data.fineCountByStatus;

        for (let key in this.finesCount) {
          this.finesCount[key].value = newData[key];
        }
      });
    }
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        this.getFines()
      }
    }
  }
};
</script>

<template>
  <Layout>
    <b-row class="pb-4">
      <b-col cols="12">
        <h2>Multas</h2>
      </b-col>
    </b-row>
    <fine-status :counters="finesCount" />
    <div v-if="loading">
      <b-card body-class="animated fadeIn p-0">
        <b-card-body class="p-0">
          <b-table responsive striped hover
                   :items="fines.items" :fields="fields"
          >
            <template v-slot:cell(car)="data">
              <b-link :to="'/fleet/' + data.item.car.id">
                <strong>{{ data.item.car.license_plate }}</strong>
                <br>
                <span
                  v-if="data.item.car.brand"
                  class="small text-muted"
                >({{ data.item.car.brand.name }} {{ data.item.car.model.name }})</span>
              </b-link>
            </template>
            <template v-slot:cell(description)="data">
              <b-badge v-if="checkIfNew(data.item.issued_at)" class="mr-1" variant="success">
                Novo
              </b-badge>
              <strong>{{ data.item.ait }}</strong>
              : {{ data.item.description }}
              <br>
              <span class="small text-muted">
                Autuador: {{ data.item.issue_institution }}
                <br>
                Local: {{ data.item.issue_location ? data.item.issue_location : 'Não identificado' }}
              </span>
            </template>
            <template v-slot:cell(driver)="data">
              <span v-if="data.item.driver">
                <b-link :to="'/drivers/profile/' + data.item.driver.id">{{ data.item.driver.name }}</b-link>
              </span>
              <span v-else>Não identificado</span>
            </template>
            <template
              v-slot:cell(issued_at)="data"
            >
              {{ data.item.issued_at | moment('DD/MM/YYYY @ HH:mm') }}
            </template>
            <template
              v-slot:cell(images)="data"
            >
              <div @click="openFineImagesModal(data.item)">
                <fine-images :images="data.item.images"/>
              </div>
            </template>
            <template v-slot:cell(amount)="data">
              <span
                :style="'color:' + getAmountColor(data.item.type)"
              >{{ setFormatPrice(data.item.amount) }}</span>
            </template>
          </b-table>
        </b-card-body>
        <b-card-body v-if="fines.errorType || fines.items.length === 0" class="p-0">
          <content-controll :service="fines" />
        </b-card-body>
      </b-card>
    </div>
    <div v-else class="animated fadeIn">
      <content-loading />
    </div>
    <b-pagination
      v-model="pagination.current"
      align="center"
      size="md"
      :total-rows="fines.count"
      :per-page="pagination.total"
    />
    <modal-fine-images
      v-model="modalShowFineImages"
      :modal_data="fineOpened"
    />  
  </Layout>
</template>

<style lang="scss" scoped>
.pw-100 {
  width: 100px;
}

.pw-140 {
  width: 140px;
}

.pw-180 {
  width: 180px;
}
</style>
